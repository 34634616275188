import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import standProjMain from "../../img/standProjMain.png"
import p1 from "../../img/y4420_11.jpg"
import p14 from "../../img/y4420_14.jpg"
import p17 from "../../img/y4420_17.jpg"
import standMainImg03 from "../../img/y4420_1.jpg"

const InterStandY4420 = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="interStandWrap">
      <div className="sectionWrap StandOverview y4420Overview">
        <p className="standProjMain">
          <img src={standProjMain} alt="" />
        </p>
        <div className="textWrap">
          <h2>
            International <span>standard</span>
          </h2>
          <h3 style={{ paddingTop: 20 }}>ITU-T SG20 Y.4420</h3>
          <p className="bodyText">
            엠투엠테크와 ETRI가 공동 개발한 엘리베이터 제어 프레임워크가
            국제전기통신연합(ITU-T) SG20 국제회의에서 '엘리베이터에 대한 IoT
            기반 모니터링 및 관리 프레임워크'로 국제표준으로 2021년 7월 공식
            승인되었습니다. 2020년 Elsa 솔루션이 ITU-T SG20 Y-series에 를 첨단
            엘리베이터 원격제어 모니터링 시스템으로 기고된데 이어, 엠투엠테크와
            ETRI는 2018년부터 3년간 과학기술정보통신부의 '스마트엘리베이터용 IoT
            게이트웨이 프로토콜 및 데이터 모델 표준개발' 사업을 진행하면서
            개발한 국내,국제 표준입니다.
          </p>
        </div>
      </div>
      <div className="sectionWrap y4420">
        <div className="textWrap">
          <h2>ITU-T SG20 Y.4420</h2>
          <p>
            <img src={standMainImg03} alt="" />
          </p>
          <p className="bodyText">
            이 표준은 승강기 산업분야에서 승강기 제어반과 IoT게이트웨이간
            상호호환성을 갖기위한 것입니다. 제조사별 자체 데이터 모델과
            프로토콜을 가지는 폐쇄적인 생태계에서 승강기 안전과 비대면
            유지보수업무의 디지털 전환을 위하여 공통 데이터를 활용하여 승강기
            원격관리, 모니터링, 점검을 목적으로 합니다. 승강기 산업 생태계에서의
            시스템 상호운용성을 통한 신서비스 창출의 기반을 마련하고, 국민의
            승강기 생활안전 및 편의 서비스를 제공하는데 있습니다.
          </p>
        </div>
      </div>
      <div className="sectionWrap ituContribution y4420Content">
        <div className="textWrap">
          <h2>표준의 내용</h2>
          <p className="bodyText">
            "Framework of Internet of things based monitoring and management for
            lifts"
            <br />이 기준은 공동 주택 단지나 빌딩의 시스템 자산 중 엘리베이터에
            대한 생활 안전 및 편의서비스를 제공하기 위한 엘리베이터
            산업표준입니다. 엘리베이터 제조사/모델별로 통신 프로토콜이 상이하여
            건물 내 설비 자산 시스템간 연동, 이기종간 통합이 어려운 현실에서
            상호운용성을 갖춰 안정적이고 안전한 연동과 관제를 도모하기 위한
            것입니다. 엘리베이터 제어반과 IoT 게이트웨이 간의 엘리베이터 구조와
            기능 요구사항, 그리고 공통 직렬 통신 프로토콜과 데이터 모델을
            정의하였습니다. 이를 위해 대기업(현대·오티스·TKE)과 중소기업(SME),
            승강기학회, 한국엘리베이터안전공사 등 30여개 기관이 참여하는
            전문표준위원회를 운영해 표준을 개발·적용하여 표준 적응성의 신뢰성을
            높였습니다.
          </p>
        </div>
        <div className="standMainImg">
          <p className="standMainImg01">
            <img src={p17} alt="" />
          </p>
          <p className="standMainImg02">
            <img src={p14} alt="" />
          </p>
          <p className="standMainImg03">
            <img src={p1} alt="" />
          </p>
        </div>
      </div>
      <div className="sectionWrap standServices">
        <div className="textWrapMain">활용 분야</div>

        <ul>
          <li>
            - 엘리베이터 제조사 독립적인 IoT게이트웨이 제품개발/표준실증 :
            <span>
              현대, 오티스, TKE, 중소기업 엘리베이터가 설치된 공동주택, 백화점,
              생산공장 등에 설치/운영 중{" "}
            </span>
          </li>
          <li>
            - 공공 분야
            <span>
              LH 승강기 통합플랫폼 설계 표준 시방에 적용(2021년 승강기대학교
              연구용역결과보고서)
            </span>
            <span>
              LH실무자 회의, 국내 승강기 산업계 기관장 거버넌스 회의를 통한
              의견수렴
            </span>
            <span>LH승강기통합관리플랫폼 표준으로 적용</span>
            <span>한국철도공사 시법서비스 진행중</span>
          </li>
          <li>
            - 스마트 홈 분야
            <span>
              공동주택단지內 스마트홈 서비스와 외부 생활서비스와 연계하여 다양한
              융합신산업 생태계 확장 가능
            </span>
          </li>
          <li>
            - 로봇 배송서비스 분야
            <span>
              승강기 로봇 택배서비스 등 미래선도 서비스 창출 기반 마련
            </span>
          </li>
          <li>
            - 안전기준 체계
            <span>승강기 산업계 표준으로 활용. </span>
            <span>
              지자체, 건물주 등 관리주체가 승강기 안전기준에 부합하는 예지보전
              시스템체계 구축
            </span>
          </li>
          <li>
            - 입주민
            <span>
              스마트 승강기 서비스 예지보전 기술 제공으로 승강기 안전사고 제로
              및 안전문화 정착
            </span>
          </li>
          <li>
            - 승강기 유지보수 분야
            <span>
              디지털 전환시기에 맞는 IoT기반의 승강기 유지관리업의 보수 품질
              제고
            </span>
          </li>
          <li>
            - 법/제도
            <span>
              승강기안전관리법/제도에 적용하여 「승강기 안전운행 및 관리에 관한
              규정」 상 제 13조의 2(승강기의 원격 관리 기능)로 정의되어 본격적인
              도입 활용 예정
            </span>
          </li>
          <li>
            - TTA 표준, 국가표준, 국제표준 제정으로 승강기 산업 활성화 기여
            <span>
              .TTA 표준 : 승강기 원격 모니터링을 위한 사물 인터넷 게이트웨이 (※
              TTAK.KO-10.1267-Part 1, 2, 3)
            </span>
            <span>
              . 국가 표준: 승강기 원격 모니터링을 위한 사물 인터넷 게이트웨이
              국가표준제정을 위한 산업기계기술심의회 진행중. 2022년 상반기중
              국가 표준 제정
            </span>
            <span>
              . 국제 표준: ITU-T SG20 Y.4420(2021.07) “Framework of Internet of
              things based monitoring and management for lifts”
            </span>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default InterStandY4420
